require(['navigation/navbar', 'swiper', 'navigation/mobile-toc-nav','pseudo-bootstrap'], (nav, Swiper, toc) => {
	function init() {
		nav.init();
		toc.init();
		const practiceQuestionsModuleElement: Element = document.querySelector("practice-questions-module");
		if (practiceQuestionsModuleElement) {
			require(["practiceQuestions/PracticeQuestionsModuleViews"], (PracticeQuestionsModuleViews: typeof import("practiceQuestions/PracticeQuestionsModuleViews")) => {
				PracticeQuestionsModuleViews.loadAndInitializeView(practiceQuestionsModuleElement);
			});
		}
		
		if (document.querySelector('.testimonial-carousel')) {
			let carousel = new Swiper('.testimonial-carousel', {
				loop: true,
				spaceBetween: 100,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				autoHeight: false
			});
		}
	}

	if (document.readyState !== 'loading') {
		setTimeout(init, 1);
	}
	else {
		document.addEventListener("DOMContentLoaded", init);
	}
});